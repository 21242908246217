<template>
  <v-container id="Damaged" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>SalesOrder Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>SalesOrder Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>SalesOrder Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table  
      ref="sales_channel_table"
      :headers="columns"
      :items="SalesChannel"
      item-key="_id"
      disable-pagination
      class="elevation-1"
      hide-default-footer
      :loading="loading"
      loading-text="Loading... Please wait"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Sales' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="8" style="padding: 25px">
          <v-row
            v-for="sales_item in item.sales_data"
            :key="sales_item.product_id"
          >
            <b>
              <span>
                <b  >{{ getVariantName(sales_item) }}</b> </span
              >&nbsp;&nbsp;&nbsp;&nbsp;
              
              <span style="width: 300px">
                <b> Sold Quantity : {{ sales_item.quantity }}</b>
              </span>
              <span style="width: 150px">
                <b> SKU Number</b> : {{ sales_item.sku }}
              </span>
              <span style="width: 150px">
                <b> UPC Number</b> : {{ sales_item.upc }}
              </span>
            </b>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.store_name`]="{ item }">
        {{ item.store_name[0].name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Sales',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Sales',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <!-- <v-icon v-if="modulePermission.Delete" @click="deleteDamaged(item)"
          >mdi-delete</v-icon
        > -->
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      sales_channels: null,
      moduleName: "Products",
      modulePermission: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      singleExpand: false,
      search: "",
      loading: true,
      SalesChannel: [],
      expanded: [],
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      dialogDelete: false,
      allProducts:[]
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "50px",
        },
        {
          sortable: false,
          text: "Sales Channel",
          value: "sales_channel",
          width: "120px",
        },
        {
          sortable: false,
          text: "Date",
          value: "date",
          width: "120px",
        },
      ];
    },
  },
  methods: {
    getSalesChannel() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "sale/products/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.SalesChannel = response.data.SalesChannel.data;
            this.loading = false;
            this.totalPages = response.data.SalesChannel.last_page;
            this.startRecord = response.data.SalesChannel.from;
            this.endRecord = response.data.SalesChannel.to;
            this.totalRecord = response.data.SalesChannel.total;
            this.numbers = [];
            for (
              let num = response.data.SalesChannel.from;
              num <= response.data.SalesChannel.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getSalesChannel();
    },
    deleteDamaged(id) {
      this.dialogDelete = true;
      this.deleteRecord = id._id;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "sale/products/delete/" +
            this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getSalesChannel();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getSalesChannel();
    },
    getVariantName(saleItem) {
      let variants = [];
      let variantName = "";
      let productName = "";
      this.allProducts.filter((product) => {
        if (product._id == saleItem.product_id) {
          productName = product.name;
          variants = product.product_variants;
        }
      });

      variants.filter((variant) => {
        if (variant._id == saleItem.variant_id) {
          variantName =
            variant.variant_quantity_per_pack +
            "x " + productName + " "
            variant.variant_volume_value +
            " " +
            variant.variant_volume_type +
            " " +
            variant.variant_type;
        }
      });
      
      return variantName;
    },
  
    getAllProducts() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAllProducts();
    this.getSalesChannel();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>
